  
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';


import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

const DailyWorkDone = lazy(() => import('src/pages/dashboard/dailyworkdone'));

export const dailyworkdoneRoutes = [
    {
        path: 'dailyworkdone',
        element: (
          // <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
          // </AuthGuard>
        ),
        children: [{ element: <DailyWorkDone />, index: true }],
      },

]






