import { useEffect, useMemo } from 'react';

// socket io

// eslint-disable-next-line import/no-extraneous-dependencies

// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// sections
import { CheckoutProvider } from 'src/sections/checkout/context';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';

import { requestNotifications } from './request-notifications';
import { indentNotification, purchaseOrderNotification } from './send-notifications';
import { TEST_SOCKET_URL } from './config-global';

import { useSocket } from './utils/socket-io';

// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------

export default function App() {
  const charAt = `

  ░░░    ░░░
  ▒▒▒▒  ▒▒▒▒
  ▒▒ ▒▒▒▒ ▒▒
  ▓▓  ▓▓  ▓▓
  ██      ██

  `;

  // const socket = useSocket();

  console.info(`%c${charAt}`, 'color: #5BE49B');

  useScrollToTop();
  // useEffect(() => {
  //   requestNotifications();

  //   socket.on('connect', () => {
  //     console.log('connected admin', socket.id);
  //   });

  //   socket.on('purchase-order-response', (data) => {
  //     console.log('purchase-order-response: ', data);

  //     socket.on('indent-approval-tab-value-response', (tabvalue) => {
  //       console.log('indent-approval-tab-value: ', tabvalue);
  //       localStorage.setItem('indentApprovalTabValue', tabvalue);
  //       // setTabValue(data);
  //     });

  //     socket.on('company-id-response', (msg) => {
  //       console.log('company-id-response: ', msg);
  //       console.log('localStorage companyId: ', localStorage.getItem('companyId'));

  //       // eslint-disable-next-line radix
  //       if (msg === parseInt(localStorage.getItem('companyId'))) {
  //         console.log('companyId matched');

  //         purchaseOrderNotification();
  //       }
  //     });
  //   });

  //   socket.on('indent-created-response', (data) => {
  //     console.log('indent-created-response', data);

  //     socket.on('indent-approval-tab-value-response', (tabvalue) => {
  //       console.log('indent-approval-tab-value: ', tabvalue);
  //       localStorage.setItem('indentApprovalTabValue', tabvalue);
  //       // setTabValue(data);
  //     });
  //     socket.on('company-id-response', (msg) => {
  //       console.log('company-id-response: ', msg);
  //       // eslint-disable-next-line radix
  //       if (msg === parseInt(localStorage.getItem('companyId'))) {
  //         indentNotification();
  //       }
  //     });
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
