import axios from 'axios';
import {
  HOST_API,
  AUTHORIZATION,
  TEST_URL,
  STAGE_AUTH_URL,
  STAGING_URL,
  TEST_URL_AUTH_HTTPS,
  PROD_URL_AUTH_HTTPS,
} from 'src/config-global';

// Create an Axios instance with a base URL and common headers
const authAxios = axios.create({
  // baseURL: `${HOST_API}:6658`,
  // baseURL: `${TEST_URL}:6658`,
  // baseURL: PROD_URL_AUTH_HTTPS,
   baseURL: STAGE_AUTH_URL,
  headers: {
    Authorization: AUTHORIZATION,
    'Content-Type': 'application/json',
  },
});

export default authAxios;
