import axios from 'axios';
// config
import { HOST_API, CORE_PORT } from 'src/config-global';

// ----------------------------------------------------------------------

// const axiosAuth = axios.create({ baseURL: `${HOST_API}:${AUTH_PORT}` });
const axiosCore = axios.create({ baseURL: `${HOST_API}:${CORE_PORT}` });

// axiosAuth.interceptors.response.use(
//   (res) => res,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

axiosCore.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

// export const axiosFunc = {
//   axiosAuth: axiosAuth,
//   axiosCore: axiosCore,
// };

export default axiosCore;

// ----------------------------------------------------------------------

// export const postAuth = async (args) => {
//   console.log('ARGS========', args);
//   const [url, data, config] = Array.isArray(args) ? args : [args, null, {}];

//   const res = await axiosAuth.post(url, data, { ...config });

//   return res.data;
// };

// export const fetchCore = async (args) => {
//   const [url, config] = Array.isArray(args) ? args : [args];

//   const res = await axiosCore.get(url, { ...config });

//   return res.data;
// };

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosCore.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  dashboardkpi: '/api/core/dashboardkpi',
  labour: { labour: '/api/core/labor', labourTypes: '/api/core/labortypes' },
  contractor: '/api/core/contractor',
};
