import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

// components
import { LoadingScreen } from 'src/components/loading-screen';

//  DashboardLayout
import DashboardLayout from 'src/layouts/dashboard';

// SUBTASKS
const SubTasksListView = lazy(() => import('src/pages/dashboard/subtasks/list'));



export const subtasksRoutes = [
  {
    path: 'subtasks',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [{ element: <SubTasksListView />, index: true }],
  },
];
