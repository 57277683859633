import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/banking'));
const CreateContractor = lazy(() => import('src/pages/contractors/createContractors'));
const EditContractor = lazy(()=> import('src/pages/contractors/editContractors'))
export const contractorRoutes = [
  {
    path: 'contractors',
    element: (
      // <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
      // </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'addcontractor', element: <CreateContractor /> },
      { path: 'editcontractor/:id', element: <EditContractor />  }
    ],
  },
];
