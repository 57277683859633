import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import LoginPage from 'src/pages/auth/firebase/login';
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { projectsRoutes } from './projects';
import { materialRoutes } from './materials';
import { UsersRoutes } from './users';
import { contractorRoutes } from './contractors';
import { categoryRoutes } from './categories';
import { approvalsRoutes } from './approvals';
import { subtasksRoutes } from './subtasks';
import { labourRoutes } from './labour';
import { dailyworkdoneRoutes } from './dailyworkdone';

// ----------------------------------------------------------------------

export default function Router() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loginDetails = JSON.parse(localStorage.getItem('loginDetails'));
    if (loginDetails !== null && loginDetails !== undefined) {
      setIsLoggedIn(true);
    }
  }, []);

  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    // path: '/',
    // element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: isLoggedIn ? (
        <Navigate to="/dashboard" replace />
      ) : (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },
    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // Projects routes
    ...projectsRoutes,

    // Material routes
    ...materialRoutes,

    // Users router
    ...UsersRoutes,

    // Contractor routes
    ...contractorRoutes,

    // Category routes
    ...categoryRoutes,

    // Approval routes
    ...approvalsRoutes, 

    // Subtasks routes
    ...subtasksRoutes,

    // Labour Routes
    ...labourRoutes,

    // Daily Work Done Routes
    ...dailyworkdoneRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
