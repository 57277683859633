// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const TEST_URL = process.env.REACT_APP_TEST_URL;
export const TEST_SOCKET_URL = process.env.REACT_APP_TEST_SOCKET_URL;
export const STAGING_URL = process.env.REACT_APP_STAGING_URL;
export const TEST_URL_AUTH_HTTPS = process.env.REACT_APP_TEST_URL_AUTH_HTTPS;
export const TEST_URL_CORE_HTTPS = process.env.REACT_APP_TEST_URL_CORE_HTTPS;
export const PROD_URL_AUTH_HTTPS = process.env.REACT_APP_PROD_URL_AUTH_HTTPS;
export const PROD_URL_CORE_HTTPS = process.env.REACT_APP_PROD_URL_CORE_HTTPS;
export const AUTH_PORT = process.env.REACT_APP_AUTH_PORT;
export const CORE_PORT = process.env.REACT_CORE_PORT;
export const AUTHORIZATION = process.env.REACT_APP_AUTHORIZATION;
export const STAGE_CORE_URL = process.env.REACT_APP_STAGE_URL_CORE_HTTPS
export const STAGE_AUTH_URL = process.env.REACT_APP_STAGE_URL_AUTH_HTTPS
export const EDCON_SOCKET = process.env.REACT_APP_STAGE_URL_SOCKET_HTTPS

export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
