import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
// import { paths } from 'src/routes/paths';
// import { RouterLink } from 'src/routes/components';
import { /* useSearchParams, */ useRouter } from 'src/routes/hooks';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
// import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { userLogin } from 'src/api/auth';

// ----------------------------------------------------------------------

export default function FirebaseLoginView() {
  // const { login, loginWithGoogle, loginWithGithub, loginWithTwitter } = useAuthContext();

  const router = useRouter();

  const [userCreds, setUserCreads] = useState({ email: null, password: null });
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  // const searchParams = useSearchParams();

  // const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const showError = (message) => {
    setErrorMsg(message);

    // Clear the error message after 3 seconds
    setTimeout(() => {
      setErrorMsg('');
    }, 3000); // 3000 milliseconds (3 seconds)
  };

  const onSubmit = handleSubmit(async (user) => {
    try {
      setLoading(true);

      const userData = {
        userType: 1,
        email: user?.email,
        password: user?.password,
      };

      userLogin(userData)?.then((loginDetails) => {
        const details = loginDetails?.loginDetails;
        if (details?.status) {
          setLoading(false);
          router.replace('/dashboard');
        } else if (!details?.status) {
          setLoading(false);
          showError('Invalid User Credentials');
          localStorage.clear();
          reset();
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      reset();
    }
  });

  /* const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleGithubLogin = async () => {
    try {
      await loginWithGithub?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      await loginWithTwitter?.();
    } catch (error) {
      console.error(error);
    }
  }; */

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      {/* <Typography variant="h4">Sign in to Minimal</Typography> */}
      <Typography variant="h2">Login</Typography>
      {/* <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">New user?</Typography>

        <Link component={RouterLink} href={paths.auth.firebase.register} variant="subtitle2">
          Create an account
        </Link>
  </Stack> */}
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <Typography variant="h5">Email</Typography>

      <RHFTextField
        data-test='email'
        name="email"
        label="Email address"
        // value={userCreds?.email}
        // onChange={(e) => {
        //   setUserCreads({ ...userCreds, email: e.target.value });
        // }}
      />

      <Typography variant="h5">Password</Typography>

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        // value={userCreds?.password}
        // onChange={(e) => {
        //   setUserCreads({ ...userCreds, password: e.target.value });
        // }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* <Link
        component={RouterLink}
        href={paths.auth.firebase.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Forgot password?
      </Link> */}

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  /* const renderLoginOption = (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>

       <Stack direction="row" justifyContent="center" spacing={2}>
        <IconButton onClick={handleGoogleLogin}>
          <Iconify icon="eva:google-fill" color="#DF3E30" />
        </IconButton>

        <IconButton color="inherit" onClick={handleGithubLogin}>
          <Iconify icon="eva:github-fill" />
        </IconButton>

        <IconButton onClick={handleTwitterLogin}>
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
        </IconButton>
      </Stack>
    </div>
  ); */

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}

      {/* {renderLoginOption} */}
    </FormProvider>
  );
}
