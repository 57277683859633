// utils
import { endpoints } from 'src/utils/axios';
import authAxios from 'src/utils/auth-axios';

export async function userLogin(loginData) {
  const URL = endpoints.auth.login;

  try {
    const res = await authAxios.post(URL, loginData);

    const details = res?.data?.Details;

    localStorage.setItem('loginDetails', JSON.stringify(res?.data?.Details));
    localStorage.setItem('companyId', details?.logindata?.company_id);
    return {
      loginDetails: res?.data,
    };
  } catch (error) {
    console.error('Error creating conversation:', error);
    return {
      error,
    };
  }
}
